<template>
  <InfoBox icon="foodableMealkit" ref="addmealstoplan">
    <div class="add-meals-content">
      <span class="main-text">Lass uns Rezepte für dich smart hinzufügen!</span>
      <div class="add-meal-buttons">
        <div class="number-selection">
          <div class="number-item">
            <div class="btn-amount" @click="changePlannedDays('minus')">
              <Icon name="removeOutline" class="icon-add-remove" />
            </div>
            <div class="amount-value" data-cy="plan-days">
              {{ $store.state.planneddays }}
            </div>
            <div class="btn-amount" @click="changePlannedDays('plus')">
              <Icon name="addOutline" class="icon-add-remove" />
            </div>
          </div>
        </div>
        <div class="go-button" @click="addMealsToNewPlan()">
          <span v-if="!loading">Los geht's</span>
          <ion-spinner v-if="loading" name="crescent"></ion-spinner>
        </div>
      </div>
    </div>
  </InfoBox>
</template>

<script>
import { IonSpinner } from '@ionic/vue'
import InfoBox from '@/components/info/InfoBox.vue'

export default {
  name: 'addmealstoplan',
  components: {
    InfoBox,
    IonSpinner
  },
  data () {
    return {
      loading: null
    }
  },
  methods: {
    changePlannedDays (method) {
      let newVal = this.$store.state.planneddays
      if (method === 'minus' && newVal > 1) {
        newVal -= 1
      } else if (method === 'plus' && newVal < 14) {
        newVal += 1
      }
      this.$store.dispatch('save', { key: 'planneddays', value: newVal })
    },
    async addMealsToNewPlan () {
      // Ensure a newplan exists and planneddays is not null
      this.setDefaultPlannedDays()
      if (!this.$store.state.newplan) await this.$store.dispatch('autoSetNewplanAndNeworder')

      return this.artificialDelay(() => {
        const details = {
          url: this.$store.state.api.plan.weekly + this.$store.state.newplan.id + '/add-recipes/',
          method: 'POST',
          data: {
            'planned_days': this.$store.state.planneddays + this.$store.state.newplan.single_days.length
          }
        }
        return this.$store.dispatch('apiSend', details).then(async newplan => {
          if (newplan && newplan.error) {
            this.$store.dispatch('showNotification', { message: 'Leider haben wir aktuell nicht genügend Rezepte, die deinen ausgeschlossenen Zutaten entsprechen. Wir kreieren aber regelmäßig neue Rezepte!', type: 'Error' })
          } else if (newplan && newplan.single_days) {
            await this.$store.dispatch('getLatestOrder')
            setTimeout(() => {
              this.$refs.addmealstoplan.$parent.$parent.$el.scrollToTop()
              this.$router.push({ name: 'cart', params: { initialTab: 'Rezepte' } }) // this needs a timeout to prevent ios from crashing ...
              }, 1000)
            this.$store.dispatch('deleteOrderReminderDripNotifications')
            this.$store.dispatch('logAnalyticsEvent', { name: 'created_new_menu' })
            if (this.$store.state.profile.show_meal_planner_explanation_popup) {
              setTimeout(() => {
                this.$store.state.showConfettiAfterMealPlanGeneration = true
                setTimeout(() => {
                  this.$store.state.showConfettiAfterMealPlanGeneration = false
                }, 3000)
              }, 800)
              setTimeout(() => this.$store.dispatch('openModal', { name: 'mealplannerexplanation' }), 2000)
            }
          }
        })
      }, 1000)
    },
    // Cause a time delay. If the meal plan generation is too fast, continue
    // to show the spinning wheel screen
    artificialDelay (task, delay) {
      this.loading = true
      const starting = new Date()
      task().then(() => {
        let passedTime = new Date() - starting
        let remainingTime = 0
        if (passedTime < delay) {
          remainingTime = delay - passedTime
        }
        setTimeout(() => {
          this.loading = false
        }, remainingTime)
      })
    },
    setDefaultPlannedDays () {
      if (!this.$store.state.planneddays) {
        this.$store.dispatch('save', { key: 'planneddays', value: 7 })
      }
    }
  },
  mounted () {
    this.setDefaultPlannedDays()
  }
}
</script>

<style lang="scss" scoped>
@import './../theme/main.scss';

.add-meals-content {
  display: inline-flex;
  justify-content: end;
  flex-direction: column;
  padding-left: 10px;
  gap: 5px;
}

.add-meal-buttons {
  display: inline-flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
}

.number-selection {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.number-item {
  display: flex;
  border-radius: #{$border-radius};
  background: $col-white;
}

.amount-value {
  @extend .main-text;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
}

.go-button {
  @extend .btn-primary-small;
  min-width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  ion-spinner {
    --color: white;
    height: 22px;
    margin: -10px 0;
  }
}
</style>